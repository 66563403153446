export class RbdSimpleModel {

  rbd: number;
  nombre: string;
  nombreCompleto: string;

  constructor(obj?: any) {
    this.rbd = obj && obj.rbd || null;
    this.nombre = obj && obj.nombre_estab || obj.nombre || null;

    if (this.rbd && this.nombre) {
      this.nombreCompleto = `${this.rbd} - ${this.nombre}`;
    }
  }
}
