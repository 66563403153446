import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PerfilModel} from '../../../modelos/perfil.model';
import {RbdSimpleModel} from '../../../modelos/rbdSimpleModel';
import {RbdService} from '../../../servicios/rbd.service';
import {UsuarioModel} from '../../../modelos/usuario.model';
import {UsuarioService} from '../../../servicios/usuario.service';
import {ToastrService} from 'ngx-toastr';
import {ifElse} from '@progress/kendo-data-query/dist/npm/funcs';

@Component({
  selector: 'app-editar-perfil',
  templateUrl: './editar-perfil-modal.component.html',
  styleUrls: ['./editar-perfil-modal.component.css']
})
export class EditarPerfilModalComponent implements OnInit {

  @ViewChild('establecimiento', null) establecimiento;

  public perfil: PerfilModel;
  public usuarioModel: UsuarioModel;
  public establecimientos: Array<RbdSimpleModel> = [];
  public perfiles: Array<any> = [{id: 1, name: 'Director'}, {id: 2, name: 'Sostenedor'}, {id: 3, name: 'Autoridad'}];
  public perfilSeleccionado: any;
  public edicion = false;
  public btnAgregarRbd = true;

  public rbdSeleccion: any;
  public keyword = 'nombreCompleto';
  public listaRBD: RbdSimpleModel[] = [];

  constructor(public activeModal: NgbActiveModal,
              public rbdService: RbdService,
              public usuarioService: UsuarioService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    if (this.perfil) {
      this.edicion = true;
      this.perfilSeleccionado = this.perfiles.find(i => i.name.toLowerCase() === this.perfil.nombre.toLowerCase());
      this.perfil.rbds.forEach(value => {
          this.rbdService.filrarRbd(value).subscribe(result => {
            this.establecimientos.push(result[0]);
          });
        }
      );
    }
  }

  selectEvent(item) {
    console.log(item);
    this.rbdSeleccion = item;
    this.btnAgregarRbd = false;
  }

  onChangeSearch(search: string) {
    if (search) {
      this.rbdService.filrarRbd(search).subscribe(
        next => {
          this.listaRBD = next;
        },
        error => {
          this.listaRBD = [];
        }
      );
    } else {
      this.listaRBD = [];
      this.rbdSeleccion = null;
    }
  }

  onFocused(e) {
    console.log(e);
  }

  addRbd() {
    if (this.rbdSeleccion) {
      if (!this.establecimientos.find(i => i.rbd === this.rbdSeleccion.rbd)) {
        this.establecimientos.push(new RbdSimpleModel(this.rbdSeleccion));
        this.rbdSeleccion = null;
        this.establecimiento.clear();
        if (this.establecimientos.length === 1 && this.perfilSeleccionado.id === 1) {
          this.btnAgregarRbd = false;
        }
      } else {
        this.toastr.warning('ya se encuentra este RBD en el perfil');
        console.log('ya se encuentra este RBD en el perfil');
      }
    }
  }

  eliminarEstablecimiento(indice: number) {
    this.establecimientos.splice(indice, 1);
    if (this.establecimientos.length === 1 && this.perfilSeleccionado.id === 1) {
      this.btnAgregarRbd = false;
    } else {
      this.btnAgregarRbd = true;
    }
  }

  guardarPerfil() {
    // const tmp = [];
    if (!this.perfil && this.perfilSeleccionado) {
      this.perfil = new PerfilModel({nombre: this.perfilSeleccionado.name, rbds: []});
    }
    // this.establecimientos.forEach(est => {
    //   if (!this.perfil.rbds.find(rbd => rbd === est.rbd.toString())) {
    //     tmp.push(est.rbd);
    //   }
    // });
    // if (tmp.length >= 1) {
    //   tmp.forEach(estTmp => {
    //     this.perfil.rbds.push(estTmp);
    //   });
    // }


    ifElse(this.perfil.rbds.length > 0, this.perfil.rbds = [], this.perfil.rbds);
    this.establecimientos.map((est: RbdSimpleModel) => {
      this.perfil.rbds.push(est.rbd.toString());
    });

    console.log(this.perfil);
    if (this.edicion) {
      this.usuarioService.editarPerfil(this.usuarioModel.usuario, this.perfil, true).subscribe(
        next => {
          console.log(next);
          this.toastr.info('Perfil agregado correctamente');
          this.activeModal.close({usuarioModel: this.usuarioModel, message: 'Edicion completa', isNew: false});
        },
        error => {
          console.log('Error al editar el prfil del usuario');
        }
      );
    } else {
      this.usuarioModel[this.perfil.nombre.toLowerCase()] = this.perfil.rbds.join(',');
      console.log(this.usuarioModel);

      this.activeModal.close({usuarioModel: this.usuarioModel, message: 'Perfil agregado', isNew: true});
    }
  }

}
