'use strict';

import {EncuestaItemModel} from './encuesta-item.model';

export class EncuestaModel {
  public items: EncuestaItemModel[];
  public lastKey: any;

  constructor(item?: any) {
    this.items = item && item.Items || [];
    this.lastKey = item && item.LastKey || {};
  }

}
