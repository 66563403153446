import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER , NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ToastrModule} from 'ngx-toastr';
import {NgxUiLoaderModule} from 'ngx-ui-loader';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthComponent} from './components/auth/auth.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {CargaMasivaComponent} from './components/carga-masiva/carga-masiva.component';
import {EditarPerfilModalComponent} from './components/modal/editar-perfil/editar-perfil-modal.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {HeaderComponent} from './components/common/header/header.component';
import {LoginComponent} from './components/login/login.component';
import {MantUsrBackofficeComponent} from './components/mant-usr-backoffice/mant-usr-backoffice.component';
import {MantUsrPortalComponent} from './components/mant-usr-portal/mant-usr-portal.component';
import {NuevoUsuarioComponent} from './components/nuevo-usuario/nuevo-usuario.component';
import {TrazabilidadComponent} from './components/trazabilidad/trazabilidad.component';
import { GridModule } from '@progress/kendo-angular-grid';

import localeCl from '@angular/common/locales/es-CL';
import {registerLocaleData} from '@angular/common';
import {KeycloakService} from './servicios/keycloak.service';
import { EncuestaSatisfaccionComponent } from './components/encuesta-satisfaccion/encuesta-satisfaccion.component';

registerLocaleData(localeCl, 'es-CL');

export function kcFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    MantUsrPortalComponent,
    MantUsrBackofficeComponent,
    TrazabilidadComponent,
    CargaMasivaComponent,
    NuevoUsuarioComponent,
    EditarPerfilModalComponent,
    AuthComponent,
    EncuestaSatisfaccionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    HttpClientModule,
    AutocompleteLibModule,
    FormsModule,
    NgxUiLoaderModule,
    GridModule
  ],
  providers: [NgbActiveModal, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor, multi: true
  },  , KeycloakService,
  {
    provide: APP_INITIALIZER,
    useFactory: kcFactory,
    deps: [KeycloakService],
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [EditarPerfilModalComponent]
})
export class AppModule {
}
