import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


declare const Keycloak: any;

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  private keycloakAuth: any;


  constructor() { }

  public init(): Promise<any> {
    return new Promise((resolve, reject) => {
      const config = {
        'url': environment.sso.url,
        'realm': environment.sso.realm,
        'clientId': environment.sso.clientId,
        'credentials': environment.sso.credentials,
        "ssl-required": "external",
        "confidential-port": 0
      };
      const initOptions = {
        responseMode: 'fragment',
        flow: 'standard',
        // onLoad: 'login-required',
        onLoad: 'check-sso',
        checkLoginIframe: false
      };
      this.keycloakAuth = new Keycloak(config);
      console.log('this.keycloakAuth 1', this.keycloakAuth);
      this.keycloakAuth.init(initOptions)
        .success(() => {
          resolve();
        })
        .error(() => {
          reject();
        });
    });
  }

  getToken(): string {
    return this.keycloakAuth.token;
  }
  getIdToken(): string {
    return this.keycloakAuth.idToken;
  }

  loadUserInfo(): Promise<any> {
    console.log(' this.keycloakAuth2', this.keycloakAuth);
    return new Promise((resolve, reject) => {
      this.keycloakAuth.loadUserProfile().success(profile => {
        resolve(profile);
      }).error(function() {
        reject();
        console.log('Ocurrio un problema al obtener los datos del usuario');
      });
    });
  }

  loadUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keycloakAuth.loadUserInfo().success(user => {
        resolve(user);
      }).error(function() {
        reject();
        console.log('Ocurrio un problema al obtener los datos del usuario');
      });
    });
  }



  logout(opciones) {
    return this.keycloakAuth.logout(opciones);
  }

  createLoginUrl(opciones) {
    console.log(opciones);
    return this.keycloakAuth.createLoginUrl(opciones);
  }

  login(opciones) {
    return this.keycloakAuth.login(opciones);
  }

  loginForm(options) {
    return this.keycloakAuth.loginForm(options);
  }

  async isAuthenticated(callback: any) {
    if (callback == null) {
      throw new Error('UserLoginService: Callback en isAuthenticated() no puede ser null');
    }

    const useData = await this.loadUserInfo();
    if (useData != null) {
      if (this.keycloakAuth.onTokenExpired) {
        callback.isLoggedIn('', false);
      } else {
        callback.isLoggedIn('', true);
      }

    } else {
      callback.isLoggedIn('', true);
    }
  }


}
