import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/login/login.component';
import {CargaMasivaComponent} from './components/carga-masiva/carga-masiva.component';
import {TrazabilidadComponent} from './components/trazabilidad/trazabilidad.component';
import {MantUsrBackofficeComponent} from './components/mant-usr-backoffice/mant-usr-backoffice.component';
import {MantUsrPortalComponent} from './components/mant-usr-portal/mant-usr-portal.component';
import {NuevoUsuarioComponent} from './components/nuevo-usuario/nuevo-usuario.component';
import {AuthComponent} from './components/auth/auth.component';
import {RouteGuardService} from './servicios/route-guard.service';
import {EncuestaSatisfaccionComponent} from './components/encuesta-satisfaccion/encuesta-satisfaccion.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'auth', component: AuthComponent},
  {path: 'mantenedor-usr-portal', component: MantUsrPortalComponent},
  {path: 'mantenedor-usr-backoffice', component: MantUsrBackofficeComponent},
  {path: 'trazabilidad', component: TrazabilidadComponent},
  {path: 'carga-masiva', component: CargaMasivaComponent},
  {path: 'nuevo-usuario', component: NuevoUsuarioComponent},
  {path: 'editar-usuario/:usuario', component: NuevoUsuarioComponent},
  {path: 'encuesta-satisfaccion', component: EncuestaSatisfaccionComponent},
  {path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
