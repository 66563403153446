import {Component, OnInit} from '@angular/core';
import {CognitoService, LoggedInCallback} from '../../servicios/cognito.service';
import {Router} from '@angular/router';
import {UsuarioService} from '../../servicios/usuario.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-carga-masiva',
  templateUrl: './carga-masiva.component.html',
  styleUrls: ['./carga-masiva.component.css']
})
export class CargaMasivaComponent implements OnInit, LoggedInCallback {

  constructor(private router: Router,
              private usuarioService: UsuarioService,
              private cognitoService: CognitoService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      // this.listarUsuarios();
    } else {
      this.toastr.error('Su sesión ha expirado');
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

}
