import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {EncuestaFiltro} from '../modelos/encuesta-filtro.model';
import {EncuestaModel} from '../modelos/encuesta.model';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {

  constructor(private http: HttpClient) {
  }

  listar(filtro: EncuestaFiltro, lastKey: any): Observable<EncuestaModel> {
    const body = JSON.stringify({
      filter: filtro,
      lastKey
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/encuesta/listar`;
    return this.http.post<EncuestaFiltro[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return new EncuestaModel(result);
      }));
  }

  exportar(): Observable<any> {
    const urlSrv = `${environment.API_DOMAINS}/encuesta/exportar`;
    return this.http.get(urlSrv);
  }

  listarRespuestas(): Observable<any> {
    const urlSrv = `${environment.API_DOMAINS}/encuesta/listar-respuestas`;
    return this.http.get(urlSrv).pipe(
      map((result: any) => {
        return result;
      }));;
  }
}
