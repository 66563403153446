import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {UsuarioService} from '../../servicios/usuario.service';
import {CognitoService, LoggedInCallback} from '../../servicios/cognito.service';

import {UsuarioModel} from '../../modelos/usuario.model';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mant-usr-portal',
  templateUrl: './mant-usr-portal.component.html',
  styleUrls: ['./mant-usr-portal.component.css']
})
export class MantUsrPortalComponent implements OnInit {

  @ViewChild('modalEliminarUsuario', null) mdlEliminarUsuario: TemplateRef<any>;

  public listUsuarios: UsuarioModel[];
  public comboUsuarios: UsuarioModel[];
  public usuarioSeleccion: UsuarioModel;
  public usuariosSeleccionadoEliminar: UsuarioModel;
  public keyword = 'usuario';
  public usuarioBusqueda: string;
  modalRef: NgbModalRef;

  constructor(private router: Router,
              private usuarioService: UsuarioService,
              private cognitoService: CognitoService,
              private toastr: ToastrService,
              private ngxService: NgxUiLoaderService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    //this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      this.listarUsuarios();
    } else {
      this.toastr.error('Su sesión ha expirado');
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  /**
   * Metodo que se encarga de obtener los usuarios desde el servidor.
   */
  listarUsuarios() {
    this.ngxService.start();
    this.usuarioService.buscar('', 'portal').subscribe(
      (data: UsuarioModel[]) => {
        console.log(data);
        this.listUsuarios = data;
        this.ngxService.stop();
      },
      error => {
        console.log(error);
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.ngxService.stop();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al cargar los usuarios.');
        }
      }
    );
  }

  /**
   * Metodo que se gatilla al seleccionar un elemento del combo.
   *
   * @param item
   */
  selectEvent(item) {
    this.usuarioSeleccion = item;
    this.listUsuarios = [];
    this.listUsuarios.push(this.usuarioSeleccion);
  }

  /**
   * Metodo que se gatilla al ir ingresando caracteres al campo de busqueda.
   *
   * @param search
   */
  onChangeSearch(search: string) {
    console.log(search);
    if (search) {
      this.comboUsuarios = this.listUsuarios.filter(item => item.usuario.includes(search));
    } else {
      this.comboUsuarios = [];
    }
  }

  /**
   * Metodo que se gatilla al entrar en el componente de busqueda.
   *
   * @param e
   */
  onFocused(e) {
    console.log(e);
  }

  /**
   * Metodo que se encarga de redireccionar.
   *
   * @param path
   */
  irA(path: string): void {
    this.router.navigate(['/' + path]);
  }

  /**
   * Metodo que se encarga de redireccionar a la edicion de un usuario.
   *
   * @param usr
   */
  editarUsuario(usr: UsuarioModel) {
    this.router.navigate(['/editar-usuario/' + usr.usuario]);
  }

  /**
   * Metodo que permite realizar la busqueda de usuarios
   */
  buscarUsuario() {
    this.ngxService.start();
    this.usuarioService.buscar(this.usuarioBusqueda, 'portal')
      .subscribe((data) => {
        console.log(data);
        this.listUsuarios = data;
        this.ngxService.stop();
      }, error => {
        console.log(error);
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.ngxService.stop();
          this.router.navigate(['/login']);
        } else if (error.status === 404) {
          this.toastr.warning('Usuario no existe.');
        } else {
          this.toastr.error('Ocurrio un error al cargar los usuarios.');
        }
      });
  }


  /**
   * Metodo que muestra el popup para confirmar la eliminación del usuario
   * @param usuario modelo para asignar a la variable que será eliminado
   */
  confirmarEliminarUsuario(usuario: UsuarioModel) {
    this.usuariosSeleccionadoEliminar = usuario;
    this.modalRef = this.modalService.open(this.mdlEliminarUsuario, {backdrop: 'static'});
  }

  /**
   * Metedo que cancela la eliminación del usuario;
   */
  cancelar() {
    this.usuariosSeleccionadoEliminar = new UsuarioModel();
    this.modalRef.close();
  }

  /**
   * Metodo que confirma la eliminación del usuario.
   */
  eliminarUsuario() {
    this.ngxService.start();
    this.usuarioService.eliminarUsuario(this.usuariosSeleccionadoEliminar.usuario, 'portal')
      .subscribe((data: any) => {
        console.log(data);
        this.usuariosSeleccionadoEliminar = new UsuarioModel();
        this.toastr.success('Usuarios eliminado exitosamente.');
        // this.ngxService.stop();
        this.modalRef.close();
        this.usuarioBusqueda = '';
        this.buscarUsuario();
      }, error => {
        console.log(error);
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.ngxService.stop();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al intentar eliminar el usuarios.');
        }
      });
  }
}
