// angular
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
// utils
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

/**
 *
 *
 * @author pumanar
 */
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(' - Interceptor URL: ' + req.url);

    let request = req;

    const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
    const idTokenKey = `${keyPrefix}.idToken`;
    const idToken = localStorage.getItem(idTokenKey);

    if (idToken) {
      request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${idToken}`)
      });
      console.log('AuthInterceptor: authorization agregado a peticion.');
    }

    return next.handle(request);
  }

}
