import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor() {
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null) {
      throw new Error(('UserLoginService: Callback en isAuthenticated() no puede ser null'));
    }

    const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
    const idTokenKey = `${keyPrefix}.idToken`;
    const accessTokenKey = `${keyPrefix}.accessToken`;

    const idToken = localStorage.getItem(idTokenKey);
    const accessToken = localStorage.getItem(accessTokenKey);

    if (idToken && accessToken) {
      callback.isLoggedIn(null, true);
    } else {
      callback.isLoggedIn('Sesion ha expirado', false);
    }
  }

}
