import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UsuarioService} from '../../servicios/usuario.service';
import {CognitoService} from '../../servicios/cognito.service';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {EncuestaService} from '../../servicios/encuesta.service';
import {EncuestaFiltro} from '../../modelos/encuesta-filtro.model';
import {EncuestaModel} from '../../modelos/encuesta.model';
import {EncuestaItemModel} from '../../modelos/encuesta-item.model';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-encuesta-satisfaccion',
  templateUrl: './encuesta-satisfaccion.component.html',
  styleUrls: ['./encuesta-satisfaccion.component.css']
})
export class EncuestaSatisfaccionComponent implements OnInit {

  public encuestaModel: EncuestaModel = new EncuestaModel();
  public encuestaFiltroModel: EncuestaFiltro = new EncuestaFiltro();

  public pagina = 1;
  public nextLastKey: any = {id: null};
  public previousLastKey: any = {id: null};
  public previousLastKeyArray: any[] = [];
  public lastKey: any = {id: null};
  public firstLastKey: any;
  public respuestas: any[] = [];
  /*public filtro: any = {
    tipo: 'rbd',
    texto: ''
  };*/
  fileName: any = 'Reporte Encuestas.xlsx';

  constructor(private router: Router,
              private usuarioService: UsuarioService,
              private cognitoService: CognitoService,
              private toastr: ToastrService,
              private encuestaService: EncuestaService,
              private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.listarRespuestas();
  }

  listarRespuestas() {
    this.encuestaService.listarRespuestas().subscribe((data: any) => {
      this.respuestas = data.respuestas;
      console.log(data);
      this.buscarEncuestas();
    });
  }

  buscarEncuestas() {

    this.ngxService.start();
    this.encuestaService.listar(this.encuestaFiltroModel, this.lastKey).subscribe((data: EncuestaModel) => {
        this.firstLastKey = !this.firstLastKey ? data.lastKey : this.firstLastKey;
        this.encuestaModel = new EncuestaModel();
        this.encuestaModel.lastKey = data.lastKey;
        data.items.forEach((x: EncuestaItemModel) => {
          const encuesta = new EncuestaItemModel(x);
          encuesta.respuestaUno = encuesta.respuestaUno != null ? this.respuestas.filter((resp: any) => resp.id === encuesta.respuestaUno)[0].descripcion : null;
          encuesta.respuestaDos = encuesta.respuestaDos != null ? this.respuestas.filter((resp: any) => resp.id === encuesta.respuestaDos)[0].descripcion : null;
          encuesta.respuestaTres = encuesta.respuestaTres != null ? this.respuestas.filter((resp: any) => resp.id === encuesta.respuestaTres)[0].descripcion : null;

          this.encuestaModel.items.push(encuesta);
        });
        if   (this.encuestaModel.lastKey.id) {
          if (this.firstLastKey.id === this.encuestaModel.lastKey.id) {
            this.previousLastKey = {id: null};
            if (!this.previousLastKeyArray.find(x => x.id === this.previousLastKey.id)) {
              this.previousLastKeyArray.push(this.previousLastKey);
            }
          } else {
            this.previousLastKey = this.nextLastKey;
            if (!this.previousLastKeyArray.find(x => x.id === this.nextLastKey.id)) {
              this.previousLastKeyArray.push(this.previousLastKey);
            }
          }
          this.nextLastKey = this.encuestaModel.lastKey;
        } else {
          this.nextLastKey = {id: null};
        }
        this.ngxService.stop();
      }
      ,
      error => {
        console.log(error);
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al cargar la trazabilidad.');
        }
      }
    );
  }

  anterior() {
    window.scroll(0, 0);
    this.pagina = this.pagina - 1;
    this.lastKey = this.previousLastKeyArray[this.pagina - 1];
    this.buscarEncuestas();
  }

  siguiente() {
    window.scroll(0, 0);
    this.pagina = this.pagina + 1;
    this.lastKey = this.nextLastKey;
    this.buscarEncuestas();
  }

  busqueda() {
    this.pagina = 1;
    this.previousLastKeyArray = [];
    this.previousLastKey = {id: null};
    this.lastKey = {id: null};

    this.encuestaFiltroModel.opcion = null;

    // this.encuestaFiltroModel[this.filtro.tipo] = this.filtro.texto;

    this.buscarEncuestas();
  }

  limpiarFiltros() {
    this.pagina = 1;
    this.previousLastKeyArray = [];
    this.previousLastKey = {id: null};
    this.lastKey = {id: null};
    this.encuestaFiltroModel = new EncuestaFiltro();
    /*this.filtro = {
      tipo: 'rbd',
      texto: ''
    };*/
    this.buscarEncuestas();
  }

  exportexcel(): void {
    const elementos: any[] = [];
    this.encuestaModel.items.forEach((ele: any) => {
      const encuesta = ele;
      delete encuesta.respuestas;
      elementos.push(encuesta);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(elementos);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Lista de Encuestas');
    XLSX.writeFile(wb, this.fileName);
  }

  /*exportarEncuestas() {
    this.ngxService.start();
    if (this.encuestaFiltroModel.tieneFiltro()) {

    } else {
      this.encuestaService.exportar()
        .subscribe((data: any) => {
          this.ngxService.stop();
          window.open(data.url, '_blank');
        }, (error) => {
          this.ngxService.stop();
          console.log(error);
        });
    }
  }*/
}
