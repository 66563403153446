import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from '../../servicios/keycloak.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  constructor(private keycloak: KeycloakService, private router: Router) {
  }

  ngOnInit() {
  }

  /*login(): void {
    const url = `${environment.COGNITO_DOMAIN}oauth2/authorize?identity_provider=${environment.PROVIDER}`
      + `&redirect_uri=${environment.REDIRECT_URI}&response_type=${environment.RESPONSE_TYPE}`
      + `&client_id=${environment.CLIENT_ID}&scope=${environment.SCOPE}`;

    window.open(url, '_self');

  } */

  login() {
    const opciones = {
      idpHint : 'google',
      redirectUri : window.location.origin + '/trazabilidad'
    };
    const link = this.keycloak.createLoginUrl(opciones);
    console.log(link);
    window.location.href = link;
  }
}
