import {Component, OnInit} from '@angular/core';
import {CognitoService, LoggedInCallback} from '../../servicios/cognito.service';
import {Router} from '@angular/router';
import {UsuarioService} from '../../servicios/usuario.service';
import {ToastrService} from 'ngx-toastr';
import {TrackingService} from '../../servicios/tracking.service';
import {TrackingModel} from '../../modelos/tracking.model';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TrackingFiltroModel} from '../../modelos/tracking-filtro.model';
import * as XLSX from "xlsx";

@Component({
  selector: 'app-trazabilidad',
  templateUrl: './trazabilidad.component.html',
  styleUrls: ['./trazabilidad.component.css']
})
export class TrazabilidadComponent implements OnInit {

  public trackingModel: TrackingModel = new TrackingModel();
  public trackingFiltroModel: TrackingFiltroModel = new TrackingFiltroModel();

  public pagina = 1;
  public nextLastKey: any = {id: null};
  public previousLastKey: any = {id: null};
  public previousLastKeyArray: any[] = [];
  public lastKey: any = {id: null};
  public firstLastKey: any;
  public filtro: any = {
    tipo: 'rbd',
    texto: ''
  };

  fileName: any = 'Reporte Tracking.xlsx';

  constructor(private router: Router,
              private usuarioService: UsuarioService,
              private cognitoService: CognitoService,
              private toastr: ToastrService,
              private trackingService: TrackingService,
              private ngxService: NgxUiLoaderService) {
  }

  ngOnInit() {
    this.buscarTrazabilidad();
    // this.cognitoService.isAuthenticated(this);
  }

  /*isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      this.buscarTrazabilidad();
      // this.listarUsuarios();
    } else {
      this.toastr.error('Su sesión ha expirado');
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  } */

  buscarTrazabilidad() {
    this.ngxService.start();
    this.trackingService.listar(this.trackingFiltroModel, this.lastKey, this.pagina).subscribe(
      (data: TrackingModel) => {
        this.firstLastKey = !this.firstLastKey ? data.lastKey : this.firstLastKey;
        this.trackingModel = data;
        if (this.trackingModel.lastKey.id) {
          if (this.firstLastKey.id === this.trackingModel.lastKey.id) {
            this.previousLastKey = {id: null};
            if (!this.previousLastKeyArray.find(x => x.id === this.previousLastKey.id)) {
              this.previousLastKeyArray.push(this.previousLastKey);
            }
          } else {
            this.previousLastKey = this.nextLastKey;
            if (!this.previousLastKeyArray.find(x => x.id === this.nextLastKey.id)) {
              this.previousLastKeyArray.push(this.previousLastKey);
            }
          }
          this.nextLastKey = this.trackingModel.lastKey;
        } else {
          this.nextLastKey = {id: null};
        }
        this.ngxService.stop();
      }, error => {
        console.log(error);
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al cargar la trazabilidad.');
        }
      }
    );
  }

  anterior() {
    window.scroll(0, 0);
    this.pagina = this.pagina - 1;
    this.lastKey = this.previousLastKeyArray[this.pagina - 1];
    this.buscarTrazabilidad();
  }

  siguiente() {
    window.scroll(0, 0);
    this.pagina = this.pagina + 1;
    this.lastKey = this.nextLastKey;
    this.buscarTrazabilidad();
  }

  busqueda() {
    this.pagina = 1;
    this.previousLastKeyArray = [];
    this.previousLastKey = {id: null};
    this.lastKey = {id: null};

    this.trackingFiltroModel.accion = null;
    this.trackingFiltroModel.rbd = null;
    this.trackingFiltroModel.usuario = null;

    this.trackingFiltroModel[this.filtro.tipo] = this.filtro.texto;

    this.buscarTrazabilidad();
  }

  limpiarFiltros() {
    this.pagina = 1;
    this.previousLastKeyArray = [];
    this.previousLastKey = {id: null};
    this.lastKey = {id: null};
    this.trackingFiltroModel = new TrackingFiltroModel();
    this.filtro = {
      tipo: 'rbd',
      texto: ''
    };
    this.buscarTrazabilidad();
  }

  /*exportarTrazabilidad() {
    this.ngxService.start();
    if (this.trackingFiltroModel.tieneFiltro()) {

    } else {
      this.trackingService.exportar()
        .subscribe((data: any) => {
          this.ngxService.stop();
          window.open(data.url, '_blank');
        }, (error) => {
          this.ngxService.stop();
          console.log(error);
        });
    }
  }*/

  exportexcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.trackingModel.items);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Lista de Encuestas');
    XLSX.writeFile(wb, this.fileName);
  }
}
