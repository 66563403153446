import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UsuarioModel} from '../modelos/usuario.model';
import {environment} from '../../environments/environment';
import {PerfilModel} from '../modelos/perfil.model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) {
  }

  buscar(usuario: string, tipo: string): Observable<UsuarioModel[]> {
    const body = JSON.stringify({
      nombreUsuario: usuario,
      tipo
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    // const urlSrv = `${environment.API_DOMAINS}/usuario/buscar`;
    const urlSrv = `${environment.API_DOMAINS}/usuario/buscar`;
    return this.http.post<UsuarioModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result.map((usr: any) => {
          return new UsuarioModel(usr);
        });
      }));
  }

  editarPerfil(usuario: string, perfil: PerfilModel, edicion: boolean): Observable<UsuarioModel[]> {
    const body = JSON.stringify({
      nombreUsuario: usuario,
      perfil: perfil.nombre,
      rbds: perfil.rbds,
      edicion
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/usuario/perfil`;
    return this.http.post<any>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result;
      }));
  }

  guardarUsuario(usuario: UsuarioModel, edicion: boolean): Observable<any> {
    const body = JSON.stringify({
      usuario,
      edicion
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    // const urlSrv = `${environment.API_DOMAINS}/usuario/guardar`;
    const urlSrv = `${environment.API_DOMAINS}/usuario/guardar`;
    return this.http.post<any>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result;
      }));
  }

  obtenerDatosUsuario(idToken: string, accessToken: string): Observable<any> {
    const body = JSON.stringify({
      AccessToken: accessToken
    });
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${idToken}`
    };

    return this.http.post<any>(`${environment.API_DOMAINS}/usuario/obtener`, body, {headers});
  }

  guardarUsuarioBackoffice(email: string, nombre: string): Observable<any> {
    const body = JSON.stringify({
      email,
      nombre
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/usuario/backoffice/guardar`;
    return this.http.post<any>(urlSrv, body, {headers});
  }

  eliminarUsuario(email: string, tipo: string): Observable<any> {
    const body = JSON.stringify({
      email,
      tipo
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    // const urlSrv = `${environment.API_DOMAINS}/usuario/eliminar`;
    const urlSrv = `${environment.API_DOMAINS}/usuario/eliminar`;
    return this.http.post<any>(urlSrv, body, {headers});
  }

}
