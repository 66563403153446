import {Component, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsuarioModel} from '../../modelos/usuario.model';
import {CognitoService, LoggedInCallback} from '../../servicios/cognito.service';
import {EditarPerfilModalComponent} from '../modal/editar-perfil/editar-perfil-modal.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UsuarioService} from '../../servicios/usuario.service';
import {PerfilModel} from '../../modelos/perfil.model';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})
export class NuevoUsuarioComponent implements OnInit, LoggedInCallback {

  public closeResult: string;
  private pathParams: Params;
  public usuarioModel: UsuarioModel = new UsuarioModel();
  public perfiles: Array<PerfilModel> = [];
  public edicion = false;


  constructor(private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private router: Router,
              private route: ActivatedRoute,
              private usuarioService: UsuarioService,
              private toastr: ToastrService,
              private cognitoService: CognitoService,
              private ngxService: NgxUiLoaderService) {
    this.route.params.subscribe(params => this.pathParams = params);
    console.log(this.pathParams.usuario);
  }

  ngOnInit() {
    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      if (this.pathParams && this.pathParams.usuario) {
        this.buscarUsuario(this.pathParams.usuario);
        this.edicion = true;
        console.log(this.usuarioModel);
      }
    } else {
      this.toastr.error('Su sesión ha expirado');
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  buscarUsuario(usuario: string) {
    this.ngxService.start();
    this.usuarioService.buscar(usuario, 'portal').subscribe(
      (data: UsuarioModel[]) => {
        console.log(data);
        this.usuarioModel = data[0];
        this.obtenerPerfiles();
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al realizar la busqueda');
        }
      }
    );
  }

  addPerfil(perfil: PerfilModel) {
    const modalRef = this.modalService.open(EditarPerfilModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.perfil = perfil;
    modalRef.componentInstance.usuarioModel = this.usuarioModel;
    modalRef.result.then((result) => {
      if (result.isNew) {
        this.usuarioModel = result.usuarioModel;
        this.obtenerPerfiles();
      } else if (!result.isNew) {
        this.buscarUsuario(this.usuarioModel.usuario);
      }
      this.closeResult = `Closed with: ${result}`;
      console.log(this.closeResult);

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(this.closeResult);
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  obtenerPerfiles() {
    this.perfiles = [];
    if (this.usuarioModel) {
      if (this.usuarioModel.director) {
        this.perfiles.push(new PerfilModel({
          nombre: 'Director',
          rbds: this.usuarioModel.director.split(',')
        }));
      }
      if (this.usuarioModel.sostenedor) {
        this.perfiles.push(new PerfilModel({
          nombre: 'Sostenedor',
          rbds: this.usuarioModel.sostenedor.split(',')
        }));
      }
      if (this.usuarioModel.autoridad) {
        this.perfiles.push(new PerfilModel({
          nombre: 'Autoridad',
          rbds: this.usuarioModel.autoridad.split(',')
        }));
      }
    }
    console.log(this.perfiles);
  }

  volver() {
    this.router.navigate(['/mantenedor-usr-portal']);
  }

  guardarUsuario() {
    console.log(this.usuarioModel);
    if (!this.usuarioModel.isValid()) {
      this.toastr.warning('Debe completar todos los campos obligatorios.');
    } else {
      this.ngxService.start();
      this.usuarioService.guardarUsuario(this.usuarioModel, this.edicion).subscribe(
        next => {
          this.ngxService.stop();
          console.log(next);
          this.toastr.success(`Usuario ${this.edicion ? 'editado ' : 'creado '} con éxito.`);
          this.router.navigate(['/mantenedor-usr-portal']);
        },
        error => {
          this.ngxService.stop();
          if (error.status === 401 || error.status === 0) {
            this.toastr.warning('Su sesión ha expirado');
            localStorage.clear();
            this.router.navigate(['/login']);
          }  else if (error.status === 409) {
            this.toastr.warning('El usuario que intenta crear ya existe.');
          } else {
            this.toastr.error(`Ocurrio un error al intentar ${this.edicion ? 'editar ' : 'crear '} el usuario`);
          }
        }
      );
    }

  }

  deletePerfil(perf: PerfilModel) {
    this.ngxService.start();
    this.usuarioService.editarPerfil(this.usuarioModel.usuario, perf, false).subscribe(
      next => {
        console.log(next);
        this.ngxService.stop();
        this.buscarUsuario(this.usuarioModel.usuario)
        this.toastr.info('Perfil Eliminado correctamente');
      },
      error => {
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.toastr.info('Error al eliminar perfil');
        }
      }
    );
  }
}
