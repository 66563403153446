export class TrackingFiltroModel {

  id: string;
  rbd: string;
  portal: string;
  usuario: string;
  ip: string;
  accion: string;
  fechaDesde: string;
  fechaHasta: string;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.rbd = obj && obj.rbd || null;
    this.portal = obj && obj.portal || null;
    this.usuario = obj && obj.usuario || null;
    this.ip = obj && obj.ip || null;
    this.accion = obj && obj.accion || null;
    this.fechaDesde = obj && obj.fechaDesde || null;
    this.fechaHasta = obj && obj.fechaHasta || null;
  }

  tieneFiltro(): boolean {
    return !!this.id || !!this.rbd || !!this.usuario || !!this.fechaDesde || !!this.fechaHasta;
  }

}
