import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from '../../../servicios/keycloak.service';

import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  actual = 'mantenedor-usr-portal';
  usuarioActual = '';

  constructor(private keycloak : KeycloakService, private router: Router) {
  }

  ngOnInit() {
    console.log(this.router.url);

    let userData: any = localStorage.getItem('UserData');
    if (userData) {
      userData = JSON.parse(userData);
      this.usuarioActual = userData.nombre;
    }

    if (this.router.url.indexOf('mantenedor-usr-backoffice') >= 0) {
      this.actual = 'mantenedor-usr-backoffice';
    } else if (this.router.url.indexOf('trazabilidad') >= 0) {
      this.actual = 'trazabilidad';
    }
  }

  irA(path: string): void {
    this.actual = path;
    this.router.navigate(['/' + path]);
  }

  //logout() {
  //  localStorage.clear();
  //  location.href = `${environment.COGNITO_DOMAIN}logout?client_id=${environment.CLIENT_ID}`
  //    + `&logout_uri=${environment.LOGOUT_URI}`;
  //}

  logout() {
    const opciones = {redirectUri: 'https://agenciaeducacion.cl/'};
    this.keycloak.logout(opciones);
  }

}
