import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CognitoService, LoggedInCallback} from '../../servicios/cognito.service';
import {Router} from '@angular/router';
import {UsuarioService} from '../../servicios/usuario.service';
import {ToastrService} from 'ngx-toastr';
import {UsuarioModel} from '../../modelos/usuario.model';

@Component({
  selector: 'app-mant-usr-backoffice',
  templateUrl: './mant-usr-backoffice.component.html',
  styleUrls: ['./mant-usr-backoffice.component.css']
})
export class MantUsrBackofficeComponent implements OnInit {

  @ViewChild('refModalAgregarUsuario', null) mdlCrearUsuario: TemplateRef<any>;
  @ViewChild('refModalEliminar', null) mdlEliminar: TemplateRef<any>;

  modalRef: NgbModalRef;
  public listUsuarios: UsuarioModel[];

  usuario: any = {
    nombre: '',
    email: ''
  };

  usuarioSel: UsuarioModel;

  constructor(private router: Router,
              private usuarioService: UsuarioService,
              private cognitoService: CognitoService,
              private toastr: ToastrService,
              private modalService: NgbModal,
              private ngxService: NgxUiLoaderService) {
  }

  ngOnInit() {
    //this.cognitoService.isAuthenticated(this);
  }

  /*isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      this.listarUsuarios();
    } else {
      this.toastr.error('Su sesión ha expirado');
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }*/

  /**
   * Metodo que se encarga de obtener los usuarios desde el servidor.
   */
  listarUsuarios() {
    this.ngxService.start();
    this.usuarioService.buscar('', 'backoffice').subscribe(
      (data: UsuarioModel[]) => {
        console.log(data);
        this.listUsuarios = data;
        this.ngxService.stop();
      },
      error => {
        console.log(error);
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al cargar los usuarios.');
        }
      }
    );
  }

  abrirModal() {
    this.usuario = {
      nombre: '',
      email: ''
    };

    this.modalRef = this.modalService.open(this.mdlCrearUsuario, {backdrop: 'static', size: 'lg'});
  }

  guardarUsuario() {
    if (this.usuario.email && this.usuario.nombre) {
      this.ngxService.start();
      this.usuarioService.guardarUsuarioBackoffice(this.usuario.email, this.usuario.nombre).subscribe(
        (next: any) => {
          this.modalRef.close();
          this.ngxService.stop();
          this.toastr.success('Usuario creado correctamente');
          this.listarUsuarios();
        },
        (error: any) => {
          console.log(error);
          this.ngxService.stop();
          if (error.status === 401 || error.status === 0) {
            this.toastr.warning('Su sesión ha expirado');
            localStorage.clear();
            this.router.navigate(['/login']);
          } else {
            this.toastr.error('Ocurrio un error al crear usuario');
          }
        }
      );

    } else {
      this.toastr.error('Debe completar todos los campos.');
    }
  }

  abrirConfirmar(seleccionado: UsuarioModel) {
    this.usuarioSel = seleccionado;
    console.log(this.usuarioSel);
    this.modalRef = this.modalService.open(this.mdlEliminar, {backdrop: 'static'});
  }

  eliminar() {
    this.ngxService.start();
    this.usuarioService.eliminarUsuario(this.usuarioSel.usuario, 'backoffice').subscribe(
      (next: any) => {
        this.modalRef.close();
        this.ngxService.stop();
        this.toastr.success('Usuario eliminado correctamente');
        this.listarUsuarios();
      },
      (error: any) => {
        console.log(error);
        this.ngxService.stop();
        if (error.status === 401 || error.status === 0) {
          this.toastr.warning('Su sesión ha expirado');
          localStorage.clear();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('Ocurrio un error al eliminado usuario');
        }
      }
    );
  }

  cancelar() {
    this.modalRef.close();
  }

}
