// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  API_DOMAINS: 'https://api-pcd2020.agenciaorienta.gob.cl/backoffice',

  USER_POOL_ID: 'us-east-1_peRzmnkKd',
  CLIENT_ID: '2uopqtp3rv1i64gfeq1kggmdv7',

  COGNITO_DOMAIN: 'https://cognito-backoffice.agenciaorienta.gob.cl/',
  PROVIDER: 'Google',
  REDIRECT_URI: 'https://localhost:4300/auth',
  LOGOUT_URI: 'https://localhost:4300/login',
  RESPONSE_TYPE: 'token',
  SCOPE: 'profile email openid aws.cognito.signin.user.admin',
  sso: {
    url: 'https://perfiladordev.agenciaeducacion.cl/auth',
    realm: 'Perfilador',
    clientId: 'Backoffice',
    credentials: {
      secret: "44bc0599-c9c5-42e7-8746-424fb867e629"
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
