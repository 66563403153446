export class PerfilModel {

  public nombre: string;
  public rbds: Array<string>;

  constructor(obj?: any) {
    this.nombre = obj && obj.nombre || null;
    this.rbds = obj && obj.rbds || null;
  }
}
