import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';

import {UsuarioService} from '../../servicios/usuario.service';

import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private loaderService: NgxUiLoaderService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private usuarioService: UsuarioService) {
  }

  ngOnInit() {
    this.loaderService.start();
    const urlParams = new URLSearchParams(this.router.url
      .replace('/auth#', '')
    );

    const accessToken = urlParams.get('access_token');
    const idToken = urlParams.get('id_token');
    const errorDescription = urlParams.get('error_description');

    if (accessToken && idToken) {
      this.usuarioService.obtenerDatosUsuario(idToken, accessToken).subscribe(
        (next: any) => {
          console.log(next);

          const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
          const idTokenKey = `${keyPrefix}.idToken`;
          const accessTokenKey = `${keyPrefix}.accessToken`;

          localStorage.clear();
          localStorage.setItem(idTokenKey, idToken);
          localStorage.setItem(accessTokenKey, accessToken);
          localStorage.setItem('UserData', JSON.stringify(next));

          this.router.navigate(['/trazabilidad']);
          this.loaderService.stop();
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      const error = urlParams.get('error_description');
      if (error.indexOf('EMAIL_NOT_EXIST') >= 0 || error.indexOf('EMAIL_DOMAIN_ERR') >= 0) {
        this.toastr.error('Usuario no autorizado para acceder al sistema.');
      } else {
        this.toastr.error('Ocurrio un error al intentar acceder con el proveedor de identidad.');
      }
      this.router.navigate(['/login']);
      this.loaderService.stop();
    }
  }

}
