export class UsuarioModel {

  public usuario: string;
  public nombre: string;
  public apellidoPaterno: string;
  public apellidoMaterno: string;
  public director: string;
  public sostenedor: string;
  public autoridad: string;
  public email: string;
  public rut: string;
  public enabled: string;
  public nombreCompleto: string;
  public password: string;
  public passwordBis: string;

  constructor(obj?: any) {
    this.usuario = obj && obj.usuario || null;
    this.nombre = obj && obj.nombre || null;
    this.apellidoPaterno = obj && obj.apellidoPaterno || null;
    this.apellidoMaterno = obj && obj.apellidoMaterno || null;
    this.director = obj && obj.director || null;
    this.sostenedor = obj && obj.sostenedor || null;
    this.autoridad = obj && obj.autoridad || null;
    this.email = obj && obj.email || null;
    this.rut = obj && obj.rut || null;
    this.password = obj && obj.password || null;
    this.passwordBis = obj && obj.passwordBis || null;
    this.enabled = obj && obj.enabled != null ? obj.enabled : true;
    this.nombreCompleto = this.getNombreCompleto();
  }

  getNombreCompleto() {
    let nombreCompleto = '';
    nombreCompleto += this.nombre ? this.nombre + ' ' : '';
    nombreCompleto += this.apellidoPaterno ? this.apellidoPaterno + ' ' : '';
    nombreCompleto += this.apellidoMaterno ? this.apellidoMaterno + ' ' : '';
    return nombreCompleto;
  }

  public isValid(): boolean {
    return !!this.usuario && !!this.nombre && !!this.apellidoPaterno
      && !!this.apellidoMaterno && !!this.email && !!this.rut;
  }

}
