import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RbdSimpleModel} from '../modelos/rbdSimpleModel';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RbdService {

  constructor(private http: HttpClient) {
  }

  filrarRbd(rbd: string): Observable<RbdSimpleModel[]> {
    const body = JSON.stringify({
      filtro: rbd
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/rbd/buscar`;
    return this.http.post<RbdSimpleModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result.map((agp: any) => {
          return new RbdSimpleModel(agp);
        });
      }));
  }
}
