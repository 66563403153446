import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {TrackingModel} from '../modelos/tracking.model';
import {TrackingFiltroModel} from '../modelos/tracking-filtro.model';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private http: HttpClient) {
  }

  listar(tracking: TrackingFiltroModel, lastKey: any, pagina: number): Observable<TrackingModel> {
    const body = JSON.stringify({
      filter: tracking,
      pagina,
      lastKey
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/tracking/listar`;
    return this.http.post<TrackingFiltroModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return new TrackingModel(result);
      }));
  }

  exportar(): Observable<any> {
    const urlSrv = `${environment.API_DOMAINS}/tracking/exportar`;
    return this.http.get(urlSrv);
  }
}
