'use strict';

import {TrackingItemsModel} from './tracking-items.model';

export class TrackingModel {
  public items: TrackingItemsModel[];
  public lastKey: any;

  constructor(item?: any) {
    this.items = item && item.Items || [];
    this.lastKey = item && item.LastKey || {};
  }

}
