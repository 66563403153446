export class EncuestaItemModel {

  id: string;
  hora: string;
  ip: string;
  fecha: string;
  opcion: string;
  perfil: string;
  respuestas: any[];
  respuestaUno: string;
  respuestaDos: string;
  respuestaTres: string;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.hora = obj && obj.hora || null;
    this.ip = obj && obj.ip || null;
    this.fecha = obj && obj.fecha || null;
    this.opcion = obj && this.obtenerSatisfaccion(obj.opcion) || null;
    this.perfil = obj && this.obtenerPerfil(obj.perfil) || null;
    this.respuestas = obj && obj.respuestas || null;
    if (obj.respuestas) {
      obj.respuestas.forEach((x: any, index: any) => {
        if (index === 0) {
          this.respuestaUno = x;
        } else if (index === 1) {
          this.respuestaDos = x;
        } else if (index === 2) {
          this.respuestaTres = x;
        }
      });
    }
  }

  public obtenerPerfil(perfil: number): string {
    let nombrePerfil: string;
    switch (perfil) {
      case 1:
        nombrePerfil = 'Director';
        break;
      case 2:
        nombrePerfil = 'sostenedor - Autoridad';
        break;
      case 3:
        nombrePerfil = 'Docente';
        break;
      case 4:
        nombrePerfil = 'Apoderado';
        break;
      case 5:
        nombrePerfil = 'Estudiante';
        break;
      case 6:
        nombrePerfil = 'Agencia';
        break;
      case 7:
        nombrePerfil = 'Otro';
        break;
    }
    return nombrePerfil;
  }

  public obtenerSatisfaccion(opcion: any): string {
    let satisfaccion: string;
    switch (opcion) {
      case 1:
        satisfaccion = 'Muy Insatisfecho';
        break;
      case 2:
        satisfaccion = 'Insatisfecho';
        break;
      case 3:
        satisfaccion = 'Neutral';
        break;
      case 4:
        satisfaccion = 'Satisfecho';
        break;
      case 5:
        satisfaccion = 'Muy Satisfecho';
        break;
    }
    return satisfaccion;
  }
}
